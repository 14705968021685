import React, { Component } from "react"
import { Link } from "gatsby"
import * as queryString from "query-string"
import emailjs from "@emailjs/browser"

import { successPageImages } from "../../constants/images.js"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default class Success extends Component {
  constructor() {
    super()
    this.state = {
      checkout: [],
      invoiceURL: [],
    }
  }
  componentDidMount() {
    this.fetchCheckout()
  }

  multiplyValues(value, quantity) {
    if (quantity > 0) {
      var total = value * quantity
    } else {
      return value.toFixed(2)
    }
    return total.toFixed(2)
  }

  fetchCheckout = () => {
    const { session_id } = queryString.parse(this.props.location.search)
    session_id != ""
      ? fetch(
          `https://www.strawpelletsdirect.com/.netlify/functions/create-session?sessionID=${session_id}`
        )
          .then(response => response.json())
          .then(data => {
            this.setState({ checkout: data })
            const amount = data.amount_total / 100
            const productTotal =
              localStorage.getItem("productPrice") *
              localStorage.getItem("quantity")
            const shippingTotal =
              localStorage.getItem("activeZone") *
              localStorage.getItem("quantity")
            //setup the email template variables
            const templateParams = {
              name: data.customer_details.name,
              status: data.status,
              checkout_id: data.payment_intent,
              total_amount: amount.toFixed(2),
			  email: data.customer_email,				
              phone: localStorage.getItem("userPhone"),
              line1: data.customer_details.address.line1,
              line2: data.customer_details.address.line2,
              city: data.customer_details.address.city,
              postal_code: data.customer_details.address.postal_code,
              quantity: localStorage.getItem("quantity"),
              product_name: localStorage.getItem("productName"),
              product_price: localStorage.getItem("productPrice"),
              product_vat: localStorage.getItem("productVat"),
              shipping_price:
                localStorage.getItem("activeZone") -
                localStorage.getItem("activeZoneVat"),
              shipping_vat: localStorage.getItem("activeZoneVat"),
              product_total: productTotal,
              shipping_total: shippingTotal,
            }
            const invoiceID = data.invoice
            fetch(
              `https://www.strawpelletsdirect.com/.netlify/functions/create-session?invoiceID=${invoiceID}`
            )
              .then(response => response.json())
              .then(data => {
                const invoiceLink = data.hosted_invoice_url
                this.setState({ invoiceURL: invoiceLink })
                templateParams.invoice_link = invoiceLink
                // send the email
                emailjs
                  .send(
                    "service_ushwrgn",
                    "template_h35k656",
                    templateParams,
                    "lAo61lM_S7v16yuYi"
                  )
                  .then(
                    function(response) {
                      console.log("Email sent!", response.status, response.text)
                    },
                    function(error) {
                      console.log("Email send failed...", error)
                    }
                  )
              })
          })
          .catch(error => {
            console.error("Error:", error)
          })
      : console.log("no sessionID")
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: "success" }}>
        <SEO title="Contact Us" />
        <div>
          <div className="fw banner-inner straw-banner-inner">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Thank you</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="fw warm-grey-bg watermark-center sect-pad-top sect-pad-bot">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="fw padd-left-wrap contact-details">
                    <h2 className="highlight">Thank you for your order</h2>
                    <p>
                      Our team are working to fulfill your order. You will
                      receive a receipt for your purchase shortly and a dispatch
                      email once your order has been processed.
                    </p>
                    <p>
                      If you have any issues please get in touch through our{" "}
                      <Link to="straw/contact">contact page</Link>
                    </p>
                    {this.state.invoiceURL != "" ? (
                      <p>
                        <a
                          href={this.state.invoiceURL}
                          className="btn btn-blue"
                          target="blank"
                        >
                          Download your invoice here
                        </a>
                      </p>
                    ) : (
                      <p>
                        <a                          
                          className="btn btn-blue disabled"
                          target="blank"
                        >
                          Your invoice will be available shortly
                        </a>
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 large-p">
                  <img
                    className="d-none d-md-block w-100"
                    src={successPageImages.afterForm}
                    alt="contact photo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
